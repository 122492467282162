import { Module } from 'vuex';
import { State as RootState } from './store';

export interface EvohealthState {
  healthGroups: any
}

const evohealth: Module<EvohealthState, RootState> = {
  state: {
    healthGroups: {
      biosignals: {
        id: 1,
        healthParams: {
          hearthRateId: 1,
          hearthRateVariabilityId: 2,
          systolicBloodPressureId: 3,
          diastolicBloodPressureId: 4,
          respiratoryRateId: 5,
          oxygenSaturationId: 6,
          stressIndexId: 7,
          ansIndexPnsId: 8,
          ansIndexSnsId: 9,
          activity: 10,
          health: 11,
          relaxation: 12,
          metabolism: 13,
          sleep: 14,
          equilibrium: 15,
          hrv_indices_hf: 16,
          hrv_indices_lf: 17,
          hrv_indices_lf_hf_ratio: 18,
          hrv_indices_mean_hr: 19,
          hrv_indices_phf: 20,
          hrv_indices_plf: 21,
          hrv_indices_rmssd: 22,
          hrv_indices_rriv: 23,
          hrv_indices_sd1: 24,
          hrv_indices_sd2: 25,
          hrv_indices_sdnn: 26,
          hrv_indices_sdnni: 27,
        }
      },
      biosignsQuestionnaire: {
        id: 2,
        healthParams: {
          pas: 28,
          height: 29,
          weight: 30,
          sex: 31,
          age: 32
        }
      }
    }

  },
};

export default evohealth;
